// @flow
import * as React from 'react'
import { Redirect } from 'react-router-dom'

import { Store } from 'Store'
import { updateLead } from 'Actions'
import ShoppingSidebar from 'components/ShoppingSidebar'
import MediaQuery from 'react-responsive'
import Header from 'components/Header'
import TopArea from 'components/TopArea'
import Footer from 'components/Footer'
import LeadGenForm from 'components/LeadGenForm/Step4'
import ShoppingCard from 'components/ShoppingCard'

import styles from './styles.module.scss'

type Props = {
    location: Location
}

export default (props: Props) => {
    const { state, dispatch } = React.useContext(Store)
    const [submitted, setSubmitted] = React.useState(false)
    const [toSelection, setToSelection] = React.useState(false)

    const handleOnSelection = React.useCallback(() => {
        setToSelection(true)
    }, [])

    if (!state.lead) {
        return <Redirect to={`${props.location.pathname.replace('/step-4', '')}${props.location.search}`} push />
    }

    if (submitted) {
        return <Redirect to={`${props.location.pathname.replace('step-4', 'success')}${props.location.search}`} push />
    }

    if (toSelection) {
        return <Redirect to={`${props.location.pathname.replace('step-4', 'step-3')}${props.location.search}`} push />
    }

    const model = state.campaign.models.find(m => m.make === state.lead.fields.newVehicleMake && m.name === state.lead.fields.newVehicleModel && m.year === state.lead.fields.newVehicleModelYear)
    const color = state.lead.fields.newVehicleModelColor

    return (
        <>
            <div className={styles.layoutWrapper}>
                <MediaQuery maxWidth={767}>
                    <TopArea />
                </MediaQuery>

                <MediaQuery maxWidth={979}>
                    <Header date={state.campaign.expiryDate} step={4} />
                </MediaQuery>

                <div className={styles.layout}>
                    <div className={styles.sidebar}>
                        <ShoppingSidebar>
                            <ShoppingCard
                                isVisible={true}
                                buttonAction={'text'}
                                buttonText={'Change selection'}
                                onClick={handleOnSelection}
                                xl
                                colorProp={color}
                                make={model.make}
                                name={model.name}
                                year={model.year}
                                voucherValue={state.campaign.incentives.voucher.type === 'variable' ? model.voucherValue : state.campaign.incentives.voucher.value}
                                startingPrice={model.startingPrice}
                            />
                        </ShoppingSidebar>
                    </div>

                    <div className={styles.step}>
                        <MediaQuery minWidth={980}>
                            <Header date={state.campaign.expiryDate} step={4} />
                        </MediaQuery>
                        <div className={styles.container}>
                            <div className={styles.formContainer}>
                                <LeadGenForm
                                    onSubmit={async (data, done) => {
                                        try {
                                            let lead = {
                                                fields: data,
                                                isComplete: true
                                            }
                                            await updateLead(dispatch, state.code, lead)
                                            setSubmitted(true)
                                        } catch (err) {
                                            alert('Oops something went wrong')
                                            console.error(err)
                                            done(err)
                                        }
                                    }}
                                    initialValues={state.lead && state.lead.fields ? state.lead.fields : {}}
                    />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <MediaQuery maxWidth={767}>
                <Footer />
            </MediaQuery>
        </>
    )
}
